<template>
  <div class="register-container">
    <div class="register-section">
      <div
        class="register-container-left"
        v-bind:style="{
          height: isModalError || isModalSukses ? '1040px' : '970px',
          'background-image': 'url(' + image + ')'
        }"
      ></div>
      <div
        class="register-container-right"
        v-bind:style="{
          height: isModalError || isModalSukses ? '1040px' : '970px',
        }"
      >
        <h1 class="register-text">Register</h1>
        <input-box
          text="Full Name*"
          textinputPlaceholder="ex. John Doe"
          v-model="name"
        ></input-box>
        <input-box
          textinputPlaceholder="ex. 081234678910"
          text="Phone Number"
          type="number"
          v-model="phone"
        ></input-box>
        <input-box v-model="email"></input-box>
        <input-box
          textinputPlaceholder="min. 8 characters"
          text="Password"
          type="password"
          v-model="password"
        ></input-box>
        <div class="flex flex-col space-y-2 mb-2">
          <div class="register-remember-me">
            <input
              type="checkbox"
              v-model="checked"
              class="register-checkbox"
            />
            <span class="register-text1 -mt-1">
              <span>
                Saya setuju
                <a
                  @click="goPage('/terms')"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="underline text-semiblack font-medium"
                  >Syarat dan Ketentuan
                </a>
                dan
                <a
                  @click="goPage('/privacy')"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="underline text-semiblack font-medium"
                >
                  Kebijakan Privasi</a
                >
              </span>
            </span>
          </div>
          <div class="register-remember-me">
            <input
              type="checkbox"
              v-model="checkedNews"
              class="register-checkbox"
            />
            <span class="register-text1 -mt-1">
              <span>
                Saya bersedia menerima Buletin dan semua Informasi Pembaruan yang ada
              </span>
            </span>
          </div>
        </div>
        <XClose v-if="isModalError" :text="error_msg" />
        <XSukses v-if="isModalSukses" :text="sukses_msg" />
        <vue-recaptcha  ref="recaptcha" :sitekey="recaptchaKey"></vue-recaptcha>
          <div @click="register()" class="register-btn-login">
            <span class="register-text4" v-if="!isLoading">Register</span>
            <div class="loader loader--style1" title="0" v-if="isLoading">
              <svg
                version="1.1"
                id="loader-1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="40px"
                height="40px"
                viewBox="0 0 40 40"
                enable-background="new 0 0 40 40"
                xml:space="preserve"
              >
                <path
                  opacity="0.2"
                  fill="#fff"
                  d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                />
                <path
                  fill="#fff"
                  d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                C22.32,8.481,24.301,9.057,26.013,10.047z"
                >
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 20 20"
                    to="360 20 20"
                    dur="0.5s"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </div>
          </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import InputBox from '../components/input-box';
  import Footer from '../components/footer';
  import XClose from '../components/x-close';
  import XSukses from '../components/x-sukses';
  import Api from '../api';
  import { VueRecaptcha } from 'vue-recaptcha';

  export default {
    name: 'Register',
    props: {},
    components: {
      InputBox,
      Footer,
      XClose,
      XSukses,
      VueRecaptcha
    },
    data() {
      return {
        email: '',
        password: '',
        name: '',
        phone: '',
        error_msg: '',
        isModalError: false,
        checked: false,
        checkedNews: 0,
        isModalSukses: false,
        sukses_msg: '',
        isLoading: false,
        checkedTerms: false,
        image: [],
        recaptchaKey: process.env.VUE_APP_KEY_RECAPTCHA
      };
    },
    mounted() {
      const recaptcha = document.createElement('script');
      recaptcha.setAttribute('src', 'https://unpkg.com/vue-demi@0.12.1/lib/index.iife.js');
      recaptcha.setAttribute('src', 'https://unpkg.com/vue-recaptcha@^2/dist/vue-recaptcha.min.js');
      document.head.appendChild(recaptcha);
    },
    async created() {
      this.$root.$on('onCloseError', () => {
        this.isModalError = !this.isModalError;
      });
      this.$root.$on('onCloseSukses', () => {
        this.isModalError = !this.isModalError;
      });

      await this.getImage();
    },
    methods: {
      register: function () {
        this.isLoading = false;
        if (!this.checked) {
          this.error_msg = 'Term and condition is required';
          this.isModalError = true;
          return false;
        }
        if (this.checkedNews) {
          this.checkedNews = 1;
        }
        const params = {
          name: this.$data.name,
          email: this.$data.email,
          phone: this.$data.phone,
          password: this.$data.password,
          password_confirmation: this.$data.password,
          is_newsfeed: this.checkedNews,
        };
        this.hideError();
        this.isModalError = false;
        this.isModalSukses = false;
        this.isLoading = true;
        this.$auth
          .register(params)
          .then((res) => {
            this.isLoading = false;
            if (res.data.status) {
              const { message } = res.data;
              this.sukses_msg = message;
              this.isModalSukses = true;
            } else {
              const { errors } = res.data;
              this.error_msg = errors[Object.keys(errors)[0]][0];
              this.isModalError = true;
              this.isModalSukses = false;
            }
            // else{
            //   this.$store.commit("setUser", res.data.user);
            //   this.$store.commit("isAuthenticated", {
            //     isAuthenticated: true,
            //   });
            //   this.$router.replace('/');
            // }
          })
          .catch((e) => {
            this.isLoading = false;
            this.error_msg = e.toString();
            this.isModalError = true;
          });
      },
      goPage: function (page) {
        this.$router.push(page);
      },
      showError() {
        this.isModalError = true;
      },
      hideError() {
        this.isModalError = false;
      },
      async getImage() {
        const rawResponse = await fetch(`${Api.baseUrl}/homepage/bannerlogin`, {
          method: 'GET',
        });
        const content = await rawResponse.json();
        if (content.status) {
          this.image = content.data.register[0].image;
        }
      },
    },
  };
</script>

<style scoped>
  .text-semiblack {
    color: #1f252c;
  }
  .register-container {
    width: 100%;
    height: auto;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .register-section {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .register-container-left {
    flex: 55%;
    width: 200px;
    height: 970px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url('/playground_assets/login_bg.jpg');
  }
  .register-container-right {
    flex: 45%;
    width: 200px;
    height: 970px;
    display: flex;
    align-items: flex-start;
    padding-top: 90px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
    background-color: #eae3d9;
  }
  .register-text {
    font-size: 3em;
    align-self: center;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .register-remember-me {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .register-checkbox {
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
  }
  .register-text1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1f252c;

    margin-left: var(--dl-space-space-halfunit);
  }
  .register-btn-login {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 64px;
    display: flex;
    position: relative;
    margin-top: 32px;
    align-items: center;
    justify-content: center;
    background-color: #1f252c;
    cursor: pointer;
  }

  .register-text4 {
    color: #fff;
    width: 100%;
    text-align: center;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.24em;
    text-transform: capitalize;
  }
  @media (min-width: 1025px) {
    .register-container-right {
      padding-left: 70px;
      padding-right: 140px;
    }
  }
  @media (max-width: 991px) {
    .register-container-left {
      display: none;
    }
    .register-container-right {
      padding: var(--dl-space-space-unit);
      padding-top: 80px;
      height: auto !important;
    }

    .register-remember-me {
      height: auto;
    }
  }
</style>
